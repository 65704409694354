import React from 'react';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import { Star } from '@components/icon/icon'

const ReviewRow = ({children}) => {
    return (
        <div className="review-row">
            <div className="review-stars">
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
            </div>
            <div className="review-row__content">
                {children}
            </div>
        </div>
    )
}

export default ReviewRow
